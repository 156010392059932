import { ref, set } from '@vue/composition-api'
import useNotifications from '@/composables/useNotifications'
import { useRouter } from '@core/utils/utils'
import i18n from '@/libs/i18n'
import realmConnection from '@/views/habit/realm'

export default function useConfirmationView() {
  const { showErrorMessage } = useNotifications()
  const { route } = useRouter()
  const { getItemsWithAggregate, ObjectId } = realmConnection()

  const confirmation = ref({})
  const isLoading = ref(true)
  const improvements = ref([])

  const show = async () => {
    try {
      const query = { _id: ObjectId(route.value.params.id) }

      const pipeline = [
        { $match: query },
        { $lookup: { from: 'worker', localField: 'worker', foreignField: '_id', pipeline: [ { $project: { name: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ], as: 'worker' } },
        { $lookup: { from: 'worker', localField: 'supervisor', foreignField: '_id', pipeline: [ { $project: { name: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ], as: 'supervisor' } },
        { $lookup: { from: 'process', localField: 'process', foreignField: '_id', pipeline: [ { $project: { name: 1, code: 1, goal: 1, description: 1, requireImage: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ], as: 'process' } },
        { $addFields: { _id: { $toString: "$_id" }, worker: { $arrayElemAt: ["$worker", 0] }, supervisor: { $arrayElemAt: ["$supervisor", 0] }, process: { $arrayElemAt: ["$process", 0] } } }
      ]
    
      const items = await getItemsWithAggregate({ collection: 'confirmation', pipeline })
      if (!items?.[0]) throw new Error('Item not found')

      confirmation.value = items[0]
      confirmation.value.activities?.forEach(e => set(e, 'showBehaviours', false))
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.confirmation_fetch_error'))
    } finally {
      isLoading.value = false
    }
  }

  const getEventData = async () => {
    try {
      const query = { confirmation: ObjectId(route.value.params.id) }

      const pipeline = [
        { $match: query },
        {
          $lookup: {
            from: 'improvement',
            localField: 'improvements',
            foreignField: '_id',
            pipeline: [
              {
                $lookup: {
                  from: 'worker',
                  localField: 'assignee',
                  foreignField: '_id',
                  pipeline: [ { $project: { name: 1, email: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ],
                  as: 'assignee',
                },
              },
              {
                $addFields: {
                  _id: { $toString: "$_id" },
                  origin_id: { $toString: "$origin_id" },
                  subscribers: { $map: { input: "$subscribers", as: "id", in: { $toString: "$$id" } } },
                  assignee: { $arrayElemAt: ["$assignee", 0] }
                }
              }
            ],
            as: 'improvements'
          }
        },
        { $project: { improvements: 1 } }
      ]
    
      const items = await getItemsWithAggregate({ collection: 'event', pipeline })
      improvements.value = items?.[0]?.improvements || []
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.event_fetch_error'))
    }
  }

  const activityScore = (activity) => {
    if (!activity.total) return "N/A"
    const ratio = (activity.score / activity.total) * 100
    return `${ratio.toFixed(1)} %`
  }

  return {
    show, confirmation, isLoading, activityScore, getEventData, improvements
  }
}
