<template lang="pug">
.habit-confirmation-wrapper
  b-row(v-if="isLoading")
    b-col(cols="12")
      b-card.py-5
        b-card-body.text-center
          loading(
            :active="true" 
            :is-full-page="false"
            :color="colors.primary"
          )

  div(v-else)
    b-row(v-if="!confirmation.process")
      h1 {{ $t('message.process_not_found') }}

    div(v-else)
      b-row
        b-col(cols="12")
          b-card(:title="processName", :sub-title="confirmation.process.goal")
            b-card-text
              div(v-if="confirmation.process.description")
                | {{ confirmation.process.description }}
              div(v-if="confirmation.worker")
                | {{ $t('message.confirmation_for') }} {{ confirmation.worker.name }}
                span(v-if="confirmation.supervisor")
                  |  {{ $t('message.done_by') }} {{ confirmation.supervisor.name }}
              div(v-if="confirmation.duration")
                | {{ $t('message.duration') }}: {{ confirmation.duration }}
              b-row.mt-2
                b-col(cols="12")
                  b-card-group.p-0(deck, v-if="confirmation.total")
                    b-card(
                      bg-variant="transparent",
                      border-variant= "success",
                    )
                      b-card-body.p-0.text-center
                        b-card-title.text-success {{ activityScore(confirmation) }}
                        b-card-text
                          | {{ $t('message.tableHeader.cumplimiento') }}

                    b-card(
                      bg-variant="transparent",
                      border-variant= "info",
                      )
                      b-card-body.p-0.text-center
                        b-card-title.text-info {{ confirmation.score }}
                        b-card-text
                          | {{ $t('message.puntaje') }}

                    b-card(
                      bg-variant="transparent",
                      border-variant= "warning",
                      )
                      b-card-body.p-0.text-center
                        b-card-title.text-warning {{ confirmation.total }}
                        b-card-text
                          | {{ $t('message.total') }}

      // Metadata
      b-row(v-if="confirmation.metadata && confirmation.metadata.length")
          b-col(cols="12")
            b-card(:title="$t('aditional_information')")
              b-card-text
                b-form-group(
                  v-for="(category, index) in confirmation.metadata", :key="index"
                  :label="$t(`metadata.${category.name === 'peak' ? 'peak_long' : category.name}`)",
                  description=""
                )
                  v-select(
                    v-if="category.type === 'select'",
                    v-model="category.answer",
                    :disabled="true"
                  )
                  b-form-input(
                    v-if="category.type === 'input'",
                    v-model="category.answer",
                    :disabled="true"
                  )

      // Activities
      b-row(v-for="(activity, idx) in confirmation.activities", :key="idx")
        b-col(cols="12")
          b-card(:title="activity.name", :sub-title="activity.goal")
            b-card-text
              | {{ activity.description }}

            b-row
              b-col(cols="12")
                b-card-group.p-0(deck, v-if="activity.total")
                  b-card(
                    bg-variant="transparent",
                    border-variant= "success",
                    )
                    b-card-body.p-0.text-center
                      b-card-title.text-success {{ activityScore(activity) }}
                      b-card-text
                        | {{ $t('message.tableHeader.cumplimiento') }}

                  b-card(
                    bg-variant="transparent",
                    border-variant= "info",
                    )
                    b-card-body.p-0.text-center
                      b-card-title.text-info {{ activity.score }}
                      b-card-text
                        | {{ $t('message.puntaje') }}

                  b-card(
                    bg-variant="transparent",
                    border-variant= "warning",
                    )
                    b-card-body.p-0.text-center
                      b-card-title.text-warning {{ activity.total }}
                      b-card-text
                        | {{ $t('message.total') }}
            
            b-row
              b-col(cols="12")
                b-button(
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'",
                  type="button",
                  :variant="activity.showBehaviours ? 'warning' : 'primary'",
                  class="mt-1 mb-1",
                  @click="activity.showBehaviours = !activity.showBehaviours"
                )
                  | {{ activity.showBehaviours ? $t('message.hide_behaviours') : $t('message.see_behaviours') }}
                  
            b-row(v-for="behaviour, index in activity.behaviours" :key="index")
              b-col(v-show="activity.showBehaviours || behaviour.type === 'text'", cols="12")
                behaviour-question(:behaviour="behaviour")

      // Image
      b-row(v-if="confirmation.imageKey")
        b-col(cols="12")
          b-card(:title="$t('message.confirmation_image')")
            b-card-text
              b-button.mr-1(
                v-ripple.400="'rgba(255, 255, 255, 0.15)'",
                type="button",
                variant="primary",
                @click="openImage(confirmation.imageKey)"
              )
                | {{ $t('message.open') }}
      
      // View improvements
      b-row(v-if="improvements && improvements.length")
        b-col(cols="12")
          b-card(
            :title="commitment_functionality ? $t('label.commitments') : $t('label.improvements')",
          )
            b-form-group
              b-col(cols="12")
                ul.list-group.mb-1
                  li.list-group-item.d-flex.justify-content-between.align-items-center.pt-0.pb-0(v-for="(improvement, index) in improvements" :key="index")
                    span
                      | {{ improvement.note }}
                    div
                      view-button(@clicked="handleImprovementView(improvement, index)")

    todo-task-handler-sidebar(
      v-model="isImprovementHandlerSidebarActive",
      :task="improvement",
      :taskIndex="improvementIndex",
      :clear-task-data="clearImprovementData",
      :domains="domains",
      :optionsMetadata="optionsMetadata",
      :readOnly="true",
      handlerId="improvement",
    )
</template>

<script>
import { ref, computed, onMounted } from '@vue/composition-api/dist/vue-composition-api'
import { BRow, BCol, BCard, BCardText, BButton, BFormGroup, BFormSelect, BCardGroup, BCardBody, BCardTitle } from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import BehaviourQuestion from './BehaviourQuestion.vue'
import useConfirmationView from './useConfirmationView'
import i18n from '@/libs/i18n'
import Loading from 'vue-loading-overlay'
import ViewButton from '@/views/components/Shared/Buttons/ViewButton.vue'
import TodoTaskHandlerSidebar from '@/views/apps/todo/TodoTaskHandlerSidebar.vue'
import useCommon from "@/views/organization/useCommon";
import useCommonDashboards from '@/views/habit/useCommonDashboards'
import store from '@/store'
import { colors } from '@/constants'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BButton,
    BFormGroup,
    BFormSelect,
    BCardGroup,
    BCardBody,
    BCardTitle,
    BehaviourQuestion,
    vSelect,
    Loading,
    ViewButton,
    TodoTaskHandlerSidebar,
  },

  directives: {
    Ripple,
  },

  computed: {
    processName() {
      return i18n.t('message.process') + (this.confirmation?.process?.name ? `: ${this.confirmation.process.name}` : '')
    },
  },

  setup() {
    const { show, confirmation, isLoading, activityScore, getEventData, improvements } = useConfirmationView()
    const { openImage } = useCommon()
    const { getDomains, getDatesFromRange } = useCommonDashboards()

    const userData = store.state?.userStore?.userData
    const clientId = userData.role !== "admin" ? userData.client.$oid : null;
    const { commitment_functionality } = JSON.parse(localStorage.getItem('clientData') || '{}')
    const TODO_APP_STORE_MODULE_NAME = 'app-todo'
    const isImprovementHandlerSidebarActive = ref(false)
    const improvement = ref({})
    const improvementIndex = ref(-1)
    const domains = computed(() => getDomains(clientId))
    const optionsMetadata = computed(() => store.state[TODO_APP_STORE_MODULE_NAME].optionsMetadata)

    const clearImprovementData = () => {
      improvement.value = {}
      improvementIndex.value = -1
    }

    const handleImprovementView = (improvementData, index) => {
      improvementIndex.value = index
      improvement.value = improvementData
      isImprovementHandlerSidebarActive.value = true
    }

    onMounted(() => {
      show()
      getEventData()
    })

    return {
      confirmation,
      isLoading,
      activityScore,
      openImage,
      improvements,
      isImprovementHandlerSidebarActive,
      improvement,
      improvementIndex,
      clearImprovementData,
      handleImprovementView,
      domains,
      optionsMetadata,
      commitment_functionality,
      colors
    }
  },
}
</script>

<style lang="scss">
.form-group {
  label {
    font-size: 1.2rem;
    font-weight: 400;
  }
}
</style>
